<template>
    <FormTemplate
        @submit="submit"
        action="Отписаться"
        class="unsub-new"
    >
        <template v-slot:desc>
            <p v-if="!info.message">
                К сожалению мы не нашли указанный Вами номер <strong>{{ data.phone | mask('+# ### ###-##-##') }}</strong><br/>
                Пожайлуста, заполните форму ниже:
            </p>
            <p v-else>
                {{ info.message }}
            </p>
        </template>
        <fieldset>
            <Field
                title="Фамилия"
                v-model.trim="form.lastname"
                :error="formErrors.lastname"
                placeholder="Иванов"
            />
            <Field
                title="Имя"
                v-model.trim="form.firstname"
                :error="formErrors.firstname"
                placeholder="Иван"
            />
        </fieldset>
        <fieldset>
            <fieldset>
                <Field
                    title="Отчество"
                    subtitle="(если есть)"
                    v-model.trim="form.patronymic"
                    :error="formErrors.patronymic"
                    placeholder="Иванович"
                />
            </fieldset>
        </fieldset>
        <fieldset>
            <fieldset>
                <Phone
                    title="Телефон"
                    subtitle="(для связи)"
                    v-model="form.phone_fio"
                    :error="formErrors.phone_fio"
                />
            </fieldset>
        </fieldset>
        <fieldset>
            <Field
                title="Первые 6 цифр карты"
                mask="#### ##"
                valueWithoutMask
                v-model="form.pan_first"
                :error="formErrors.pan_first"
                placeholder="1234 56"
            />
            <Field
                title="Последние 4 цифры"
                mask="####"
                valueWithoutMask
                v-model="form.pan_last"
                :error="formErrors.pan_last"
                placeholder="1234"
            />
        </fieldset>
    </FormTemplate>
</template>

<script>
import Phone from '@/components/form/field/Phone'

import mask from '@/helpers/string/setMask'

import validation from '@/mixins/validation'
import Validation from '@/ext/validation/Validation'

import Store from '@/store'

import { NAME_RULES, NAME_ERROR_MSG } from '@/const/validationValues'

import sendUnsubscribe from '@/api/sendUnsubscribe'
import FormTemplate from '@/templates/form/Form';

export default {
    name: 'UnsubscribeInfo',
    mixins: [validation],
    data() {
        return {
            form: {
                lastname: '',
                firstname: '',
                patronymic: '',
                phone_fio: '',
                pan_first: '',
                pan_last: '',
            },
            formRules: {
                lastname: [Validation.REQUIRED, ...NAME_RULES],
                firstname: [Validation.REQUIRED, ...NAME_RULES],
                patronymic: NAME_RULES,
                phone_fio: [Validation.REQUIRED, Validation.PHONE],
                pan_first: [Validation.REQUIRED, [Validation.MIN, 6]],
                pan_last: [Validation.REQUIRED, [Validation.MIN, 4]],
            },
            customErrors: {
                firstname: NAME_ERROR_MSG,
                lastname: NAME_ERROR_MSG,
                patronymic: NAME_ERROR_MSG
            }
        }
    },
    computed: {
        info() {
            return this.$route.params.info
        },
        data() {
            return this.$route.params.data
        }
    },
    methods: {
        async submit() {
            this.validate()

            if (!this.isValid)
                return

            const info = await sendUnsubscribe({
                ...this.form,
                phone_not_found: this.data.phone
            })

            this.$router.push({
                name: 'UnsubscribeMessage',
                params: {
                    info,
                    data: {
                        ...this.form,
                        ...this.data
                    }
                }
            })
        }
    },
    components: {
        FormTemplate,
        Phone,
    },
    filters: {
        mask
    },
    beforeRouteEnter(to, from, next) {
        const {
            info,
            data
        } = to.params

        if (info && data) {
            next()
        } else {
            Store.commit('application/load', false)
            next({
                name: 'Unsubscribe'
            })
        }
    }
}
</script>

<style lang="scss">
.unsub-new {
  background-color: #FEFEFF;
  padding: 42px 36px;
  .form-t__content {
    max-width: 540px;
  }
  .form-t__actions {
    margin-top: 24px;
    button {
      max-width: 243px;
    }
  }

  @media(max-width: $mobile) {
    .field {
      width: 100%;
    }
    .form-t__actions {
      button {
        margin: 0 auto;
      }
    }
  }
}
</style>