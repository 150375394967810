import { year, month, day } from '@/helpers/date/currentDate'
import Validation from '@/ext/validation/Validation'

export const MIN = 2
export const MAX = 255
export const NAME_EXP = /(^['’а-яА-ЯёЁ-]*$)|(^$)/
export const FULL_NAME_EXP = /(^[ '’а-яА-ЯёЁ-]*$)|(^$)/
export const CARD_OWNER_EXP = /^[a-zA-Z ]*$/

export const MAX_BORROWER_OLD = 90
export const MIN_BORROWER_OLD = 18

export const MAX_BORROWER_YEAR = `${day}.${month}.${year-MAX_BORROWER_OLD}`
export const MIN_BORROWER_YEAR = `${day}.${month}.${year-MIN_BORROWER_OLD}`

export const NAME_RULES = [Validation.NO_FILTHY, [Validation.EXP, NAME_EXP], [Validation.MIN, MIN], [Validation.MAX, MAX]]
export const NAME_ERROR_MSG = {
    [Validation.EXP]: 'Допустимы только русские буквы, дефис и апостроф'
}